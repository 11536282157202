import Link from "next/link";

import styles from "./index/error.page.module.scss";
import {useTranslation} from "next-i18next";
import {fetchTranslations} from "utils/fetches";

const ErrorPage = (props) => {
  const {t} = useTranslation();

  return (
    <div className={styles.errorPage}>
      <div className={styles.content}>
        <div className={styles.title}>
          <div className={styles.main}>404</div>
          <div className={styles.overlay}>{t("Stránka neexistuje")}</div>
        </div>
        <div className={styles.subtitle}>
          {t("Zablúdili ste?")}
        </div>
        <p dangerouslySetInnerHTML={{
          __html: t("Navštívte naše Turistické informačné centrum v Mestskej veži,<br/> kde vás radi nasmerujeme k najzaujímavejším trnavským miestam či podujatiam")
        }}/>
        <Link href="/" as={`/`}>
          <a className={`btn btn-white`}>
            {t("Chcem sa vrátiť do Trnavy")}
          </a>
        </Link>
      </div>
    </div>
  );
};

export default ErrorPage;